import React, { Component, Fragment, useContext } from 'react';
import { Route, Routes } from 'react-router-dom'

import { NavTopBar } from './components/NavTopBar';
import { Footer } from './components/Footer';

import { HomeTitle } from './components/HomeTitle';

import Home from './components/Home';
import DocumentList from './components/DocumentList';
import ContestSlider from './components/VotingParentPage';
import Results from './components/Results';
import Ticket from './components/Ticket';
import BlankComponent from './components/BlankComponent';

import { Privacy } from './components/Privacy';
import { Help } from './components/Help';
import { Accessibility } from './components/Accessibility';

import { LoginLayout } from './components/LayoutLogin';
import { ProxyLayout } from './components/LayoutProxy';
import { PolicyLayout } from './components/LayoutPolicy';

import { Login } from './components/login/Login';
import { LoginBypass } from './components/login/LoginBypass';

import { Logout } from './components/login/Logout'
import { DuplicateLogout } from './components/login/DuplicateLogout'
import { ForcedLogout } from './components/login/ForcedLogout'

import { CreateGuestUser } from './components/login/CreateGuestUser'
import { ForgotCodes } from './components/login/ForgotCodes'

import { SecureComponent } from './components/login/PrivateRoute'
import { NotificationContainer } from 'react-notifications';
import { Rescind } from './components/AttendingAndProxy/RescindVote'
import { ProxyPollCard } from './components/AttendingAndProxy/ProxyPollCard'
import { ProxyRouter } from './components/AttendingAndProxy/ProxyPageRouter'
import PageLoadMonitor from './components/PageLoadMonitor'
import { SignalRContextConsumer } from './components/SignalRComms'
import 'react-notifications/lib/notifications.css';
import history from "./utils/history";
import { Forbidden } from './components/Forbidden';
import './custom.css'
import UserContext from './components/context/UserContext';
import { LeftLayout } from './components/LayoutMainColumns';
import { OpenChatContextProvider } from './components/OpenChat/Common/OpenChatContext/OpenChatContextProvider';
import GetInvolved from './components/GetInvolved'

//import SurveyParent from './components/Survey/SurveyParent';
import AudioVisual from './components/AudioVisual';
import { ZoomClientWrapper } from './components/Zoom/ZoomClientWrapper';


const NotFound = () => { return <div> <h2>404 - Not Found!</h2><p>The page you have requested has not been found, please check the address and try again.</p> </div> };


export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {

        };
        console.log("Attendee Version 3.10.0");
    }

    skipToContent = (e) => {
        e.preventDefault();
        if (document.getElementById('skipToContent')) {
            document.getElementById('skipToContent').focus();
            window.location.hash = '#skipToContent';
        }
    }

    componentDidMount() {
        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                // Page became visible
                this.context.populateMeetingAndUser();
            }
        });
    }

    render() {
        return (
            <Fragment>
                <div className="outerlimits">
                    <a className="skiptocontent" href="#skipToContent" onClick={(e) => this.skipToContent(e)}>Skip to content</a>

                    <NotificationContainer />
                    <Routes>
                        <Route path='/login/*' element={<LoginPath />} />
                        <Route path='/proxy/ProxyPollCard' element={<SecureComponent component={ProxyPath} />} />
                        <Route path='/Privacy' element={<PrivacyPath />} />
                        <Route path='/Help' element={<HelpPath />} />
                        <Route path='/Accessibility' element={<AccessibilityPath />} />
                        <Route path='/ZoomContainer' element={<SecureComponent component={ZoomClientWrapper} />} />
                        <Route path='/*' element={<SecureComponent component={MainPagePath} />} />
                    </Routes>
                </div>
            </Fragment>
        );
    }
}

App.contextType = UserContext;

//Updates for RouterV6
const LoginPath = (props) => {
    return (
        <div id="skipToContent" tabIndex="-1">
            <LoginLayout>
                <Routes>
                    <Route path='/loginbypass' element={<LoginBypass />} />
                    <Route path='/NGuest' element={<CreateGuestUser />} />
                    <Route path='/Forgot' element={<ForgotCodes />} />
                    <Route path='/logout' element={<Logout />} />
                    <Route path='/DuplicateLogout' element={<DuplicateLogout />} />
                    <Route path='/ForcedLogout' element={<ForcedLogout />} />
                    <Route path='/RescindVote' element={<SecureComponent component={Rescind} />} />
                    <Route path='/ProxyRouter' element={<SecureComponent component={ProxyRouter} />} />
                    <Route path='/' element={<Login />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </LoginLayout>
        </div>
    )
}
const ProxyPath = props => {
    return (
        <Fragment>
            <div id="skipToContent" tabIndex="-1">
                <ProxyLayout>
                    <ProxyPollCard />
                </ProxyLayout>
            </div>
        </Fragment>
    );
}
const PrivacyPath = props => {
    return (
        <Fragment>
            <div id="skipToContent" tabIndex="-1">
                <PolicyLayout>
                    <Privacy />
                </PolicyLayout>
            </div>
        </Fragment>
    );
}
const HelpPath = props => {
    return (
        <Fragment>
            <div id="skipToContent" tabIndex="-1">
                <PolicyLayout>
                    <Help />
                </PolicyLayout>
            </div>
        </Fragment>
    );
}
const AccessibilityPath = props => {
    return (
        <Fragment>
            <div id="skipToContent" tabIndex="-1">
                <PolicyLayout>
                    <Accessibility />
                </PolicyLayout>
            </div>
        </Fragment>
    );
}

const MainPagePath = (props) => {
    const context = useContext(UserContext);
    let attending = context.fullState.attendanceStatus;
    let switches = context.switches;
    let okToLoad = parseInt(context.user.userID) > 0 && parseInt(context.meeting.vmId);

    let showVideoStream = ((!attending && switches.enableAudioVideo) || (attending && switches.inRoomAudiovisual));
    let showOpenChat = ((!attending && switches.enableOpenChat) || (attending && switches.inRoomOpenChat));
    let showAskAQuestion = context.user.isMember && ((!attending && switches.enableQA) || (attending && switches.inRoomChat))
    //let showSurvey = !attending && ( switches.enableSurvey || this.context.fullState.surveyPopup )
    let backgroundImage = context.meeting.backgroundImage ? { backgroundImage: `url(data:image/jpeg;base64,${context.meeting.backgroundImage})` } : {};

    return (<Fragment>
        <OpenChatContextProvider userName={context.user.userName}>
            {okToLoad > 0 && <SignalRContextConsumer history={history} />}
            <PageLoadMonitor />
            <div className="appParent">
                {context.user.test === "True" && !switches.demo && <div className="alert alert-secondary me-0 py-1" role="alert">You are logged in under test conditions, submitted votes will not be counted in final totals</div>}
                <div className="headerContent">
                    <header>
                        <div>
                            <div className="topbar">
                                <NavTopBar />
                            </div>
                            <div className="titlebar sr-only">
                                <HomeTitle />
                            </div>
                        </div>
                    </header>
                </div>
                <div className="mainContent py-1" style={backgroundImage} >
                    <main id="skipToContent" tabIndex="-1">
                        <div className="row fillheight flex-wrap-reverse ">
                            <LeftLayout showVideoStream={(showVideoStream && !!switches.meetingType)}>
                                <Routes>
                                    <Route path='/' element={<SecureComponent component={Home} />} />
                                    <Route path='/DocumentList/*' element={<SecureComponent component={((!attending && switches.enableDocuments) || (attending && switches.inRoomDocuments)) ? DocumentList : Forbidden} />} />
                                    <Route path='/Contests' element={<SecureComponent component={((!attending && switches.remoteVoting) || (attending && switches.inRoomVoting)) ? ContestSlider : Forbidden} />} />
                                    <Route path='/Results' element={<SecureComponent component={((!attending && switches.results && switches.enableVoting) || (attending && switches.inRoomResults && switches.enableVoting)) ? Results : Forbidden} />} />
                                    <Route path='/Ticket' element={<SecureComponent component={attending && switches.ticket ? Ticket : Forbidden} />} />
                                    <Route path='/Video' element={<SecureComponent component={showVideoStream ? BlankComponent : Forbidden} />} />
                                    <Route path='/Q&A' element={<SecureComponent component={showAskAQuestion ? GetInvolved : Forbidden} />} />

                                    <Route element={<NotFound />} />
                                </Routes>
                            </LeftLayout>

                            {(showVideoStream || showOpenChat) &&
                                <AudioVisual switches={switches} attending={attending} />
                            }
                        </div>
                    </main>
                </div>
                <div className="footerContent">
                    <Footer />
                </div>
            </div>
        </OpenChatContextProvider>
    </Fragment>)
}