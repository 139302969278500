import React from 'react';
import { Route, Routes } from 'react-router-dom'
import BlankComponent from './BlankComponent';

export function LeftLayout(props) {

    if (props.showVideoStream) {
        return (
            <Routes>
                <Route path='/Video' element={<BlankComponent />} />
                <Route path='/DocumentList/Viewer' element={<ViewerPath {...props} />} />
                <Route path='/*' element={<GenericPath {...props}/>} />
            </Routes>
        );
    } else {
        return (
            <div className="col-md leftColumn">
                {props.children}
            </div>
        )
    }
}


//Updates for RouterV6
const ViewerPath = props => <div className="col-md-8 leftColumn scrollFix" >{props.children} </div>   


const GenericPath = props => <div className="col-lg-4 col-md-6 leftColumn scrollFix" >{props.children}</div>


