import { jwtDecode } from 'jwt-decode';
import { NotificationManager} from 'react-notifications'
export class AuthService
{
    _baseUrl = 'api/users/';
 

    saveUserData(vmUser) {
        // Saves user data to SessionStorage 
        sessionStorage.setItem('vm_user', vmUser);
    }
    getUserole() {
        // Retrieves the user token from localStorage
        let user = JSON.parse(sessionStorage.getItem('vm_user'));
        if (user && user.role) {
            return user.role
        }
        return '';
    }
    getusername() {
        // Retrieves the user token from localStorage
        let user = JSON.parse(sessionStorage.getItem('vm_user'));
        if (user && user.username) {
            return user.username
        }
        return '';
    }
    getToken() {
        // Retrieves the user token from localStorage
        let user = JSON.parse(sessionStorage.getItem('vm_user'));   

        if (user && user.token) {
            return user.token

        }
        return '';
    }

    isTokenExpired(token) {
        try {
            if (token === null) { return false; }
            const timenow = Math.floor(Date.now() / 1000);
            const decoded = jwtDecode(token);

            let tokendate = new Date(decoded.exp * 1000);
            let strdatetime = "Last Sync: " + tokendate.getDate() + "/"
                + (tokendate.getMonth() + 1) + "/"
                + tokendate.getFullYear() + " @ "
                + tokendate.getHours() + ":"
                + tokendate.getMinutes() + ":"
                + tokendate.getSeconds();


            if (decoded.exp < timenow) {
                NotificationManager.info("Authentication Token Expired", "logging out", 3000);
                return true;
            }
            else {
                return false;
            }
        }
        catch (err) {
            return false;
        }
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken()
        return !!token && !this.isTokenExpired(token)
    }

    logout() {
        // Clear user token and profile data from localStorage
        sessionStorage.removeItem('vm_user');
        sessionStorage.clear();
        NotificationManager.info("User logged out", "", 3000);
    }

    userProfile() {

        if (!this.loggedIn()) { return null; }
        let user = sessionStorage.getItem('vm_user');
        return user;
    }

    PostData(type, userData) {

        //let BaseUrl = 'api/users/';
        //text/plain  application/json
        return new Promise((resolve, reject) => {

            fetch(this._baseUrl + type, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData)
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    resolve(responseJson);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    static get instance() { return authService }
}

const authService = new AuthService();

export default authService;