import React, { useCallback, useMemo, } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact, } from 'slate-react';
import { getMarked, getBlock } from './utils/SlateUtilityFunctions.js'
import withLinks from './plugins/withLinks.js'
import withTables from './plugins/withTable.js'
import withEmbeds from './plugins/withEmbeds.js'
import './Editor.css'


const Element = (props) => {
    return getBlock(props);
}
const Leaf = ({ attributes, children, leaf }) => {
    children = getMarked(leaf, children);
    return <span {...attributes}>{children}</span>
}

const initialValue = [
    {
        type: 'paragraph',
        children: [{ text: 'Welcome' }],
    },
]


const SlateEditor = (props) => {
    const editor = useMemo(() => withEmbeds(withTables(withLinks(withReact(createEditor())))), []);

    const checkPropsValueValid = () => {
        var message = isJsonString(props.value) ? JSON.parse(props.value) : "";
        var response = Array.isArray(message) ? message : initialValue
        return response;
    }


    const renderElement = useCallback(props => <Element {...props} />, [])

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, [])

    return (
        <div className="slate-editor">
            <Slate
                initialValue={checkPropsValueValid()}
                editor={editor}
                value={props.value}
                >
                <div className="editor-wrapper" >
                    <Editable
                        readOnly={true}
                        placeholder='Write something'
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                    />
                </div>


            </Slate>
        </div>
    )
}

export default SlateEditor


function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}