import React, { Component, Fragment } from 'react';
import { Nav } from "react-bootstrap";
import {  NavLink } from "react-router-dom";
import authService from '../_services/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import SignalRManager from './SignalRManager';


export class LoginMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null
        };
        this.connectionListener = this.connectionListener.bind(this);
    }

    componentDidMount() {
        this.populateState();
        SignalRManager.addConnectionListener(this.connectionListener);
    }

    componentWillUnmount() {
        SignalRManager.removeConnectionListener(this.connectionListener);
    }

    connectionListener() {
        // Add code to identify signalR issues
        return;
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.loggedIn(), authService.getusername()])
        this.setState({
            isAuthenticated,
            userName: user && user.username
        });

    }
    handleLogout(e) {

        authService.logout();
    }



    render() {
        const { isAuthenticated } = this.state;
        if (!isAuthenticated) {
            const loginPath = '../login/';
            return this.anonymousView(loginPath);
        }
        else {
            const logoutPath = { pathname: '../login/logout', state: { local: true } };
            return this.authenticatedView(logoutPath);
        }
    }

    /*<Prompt
      This functionality is no longer available, we'll need to write a new function
      import swal from 'sweetalert'
                message={(location) => {
                    return location.pathname.endsWith("/logout")
                        ? `Are you sure you want to leave?`
                        : true
                }}
            />*/

    authenticatedView(logoutPath) {
        return (<Fragment>
            
            <Nav.Item className="logout">
                <NavLink className="btn btn-danger text-white rounded pt-1 pb-1" to={logoutPath}>
                    <FontAwesomeIcon icon={faSignOutAlt} />&nbsp;Leave Meeting
                </NavLink>
            </Nav.Item>

        </Fragment>)
    }

    anonymousView(loginPath) {
        return (<Fragment>
                <Nav.Item className="logout">
                <NavLink className="nav-link"  to={loginPath}>
                        <FontAwesomeIcon icon={faSignInAlt} />&nbsp;Login
                    </NavLink>
                </Nav.Item>
        </Fragment>)
    }



}