import React, { Component, Fragment } from 'react';
import UserContext from '../context/UserContext';
import { Link, Navigate } from 'react-router-dom';
import authService from '../../_services/AuthService';
import history from "../../utils/history"

const createHistory = require("history").createBrowserHistory;

export class ProxyPollCard extends Component {
    static displayName = ProxyPollCard.name;

    constructor(props) {
        super(props);

        this.state = {
            submitLoading: false,
            sumitted: false,
            loading: true,
            proxyPollCard: {},
            agree: false,
            reject: false,
            autoredirect: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.populateProxyPollCard = this.populateProxyPollCard.bind(this);
        this.handleRejectChange = this.handleRejectChange.bind(this);
    }

    componentDidMount() {
        document.title = "CESJoinIN - Proxy";
        if (this.context.fullState.contextLoading === true) {
            this.checkPopulated();
        }
        if (this.context.fullState.contextLoading === false) {
            this.populateProxyPollCard();
        }
        this.setState({
            contextLoading: this.context.fullState.contextLoading,
        })
    }

    componentDidUpdate() {
        if (this.state.contextLoading != this.context.fullState.contextLoading) {
            this.populateProxyPollCard();
            this.setState({
                contextLoading: this.context.fullState.contextLoading
            })
        }
    }

    handleSubmit() {
        this.context.setArbStatus('pollCardSubmitted', true);
        this.setState({
            submitted: true
        })
        setTimeout(() => {
            this.setState({
                autoredirect: true
            })
        }, 3000);

    }

    getAppointmentCount() {
        let appointorCount = 0;
        if (this.context.voteData.length > 0) {
            Object.keys(this.context.voteData).forEach((key) => {
                let data = this.context.voteData[key].proxyVoting;
                if (data) {
                    let appointorCounteach = 0;
                    data.forEach(function (option) {
                        appointorCounteach += option.proxyVoteCount;
                    })
                    if (appointorCounteach > appointorCount) {
                        appointorCount = appointorCounteach
                    }
                }
            });
        }
        return appointorCount;
    }


    ConfirmProxy = async (event) => {
        event.preventDefault();
        let errors = {};
        let params = [];
        this.setState({ errors: '' });

        if (this.state.reject) {
            const token = authService.getToken();


            await fetch('VMRejectProxyVote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            }).then(response => {
                if (!response.ok) {
                    throw new Error("Can't set proxy option");
                }
                return response.json();
            }).then(data => {
                this.setState({
                    loading: false
                });
            });

            this.handleSubmit();

        }
        else if (this.state.agree) {
            if (this.context.voteData != null && this.context.voteData.length > 0 && this.context.meeting.vmId > 0) {

                this.context.voteData.forEach((proxy) => {
                    let CESVMContestId = proxy.contestID;
                    if (CESVMContestId > 0 && this.context.user.userID > 0) {
                        params.push({
                            VMId: parseInt(this.context.meeting.vmId),
                            JobNo: this.context.meeting.jobNo,
                            CESVotersID: parseInt(this.context.user.userID),
                            CESVMContestId: CESVMContestId,
                            VoteValue: "Discretionary"
                        })
                    }
                    else {
                        if (errors["proxy"])
                            errors["proxy"] = errors["proxy"] + " , " + proxy.title;
                        else
                            errors["proxy"] = "Please select your voting option for the following item: " + proxy.title;
                    }
                }

                )

                if (errors["proxy"] != null && errors["proxy"].length > 0) {
                    this.setState({ errors: errors });
                }
                else {
                    const token = authService.getToken();
                    const response = await fetch('VMConfirmProxyVote', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(params)
                    });
                    const resdata = await response.json();
                    if (resdata) {
                        errors["proxy"] = "items submitted successfully";
                        this.setState({
                            errors: errors,
                            proxyConfirm: true
                        });

                        this.handleSubmit();
                    }
                }
            }
        } else {
            errors["proxy"] = "Please indicate whether you accept or reject your appointment as proxy";
            this.setState({
                errors: errors
            });
        }
    }

    handleRejectChange(event) {
        let target = event.target;
        let ticktarget = event.target.name;
        let other = ticktarget === "agree" ? "reject" : "agree";
        if (target.checked) {
            this.setState({
                [ticktarget]: target.checked,
                [other]: !target.checked,
            })
        } else {
            this.setState({
                [ticktarget]: target.checked
            })
        }
    }

    render() {
        const clickLoad = this.state.submitLoading;
        const loading = this.state.loading;
        const data = Object.values(this.context.voteData).filter(contest => contest.contestTypeId === 1);
        const counter = this.getAppointmentCount();

        if (loading === true || this.context.fullState.contextLoading === true) {
            return (
                <div className="loadingOverlay">
                    <div className="loader"></div>
                </div>
            );
        }

        if (this.state.autoredirect === true) {
            return (<Navigate to="/login/ProxyRouter" />);
        }

        if (this.state.submitted === true || this.context.user.isProxy === false) {
            return (
                <div className="cloud">
                    <h3 className="loginHeading">Thank you for submitting your proxy pollcard.<hr /></h3>
                    <div className="loginbox">
                        If you are not redirected within 5 seconds, please click the button below.
                    </div>
                    <Link to="/"><button className="btn btn-primary">Next</button></Link>
                </div>
            );
        }

        return (
            <Fragment>
                <div className="cloud">
                    <h3 className="loginHeading">Proxy Poll Card<hr /></h3>
                    <div className="loginbox">
                        <form onSubmit={this.ConfirmProxy}>
                            {data != null && data.length > 0 &&
                                <Fragment>
                                    <div className="cloud">
                                        <p>{this.context.user.userName}, you have been named as a proxy for {counter} {counter === 1 ? 'person' : 'people'} </p>
                                        <p>A breakdown of how they wish you to cast votes on their behalf is below. For any discretionary votes you will be able to cast the votes when the items/questions are open for voting.</p>
                                        <p>If you are happy to act as proxy please tick 'I agree to act as a proxy'. If you do not wish to act as a proxy please tick 'I do not agree to act as a proxy' and then confirm your choice.</p>
                                    </div>
                                    <div className="datatableParent">
                                        <ProxyDataTable data={data} />
                                        {this.state.reject &&
                                            <div className="rejectOverlay">
                                                <div>
                                                    <p>If you decline to act as proxy, none of the votes assigned to you as a proxy will be counted.</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Fragment>
                            }
                            <div className="acceptReject">
                                <div className="mb-2">
                                    <input
                                        id="agreeProxy"
                                        name="agree" type="checkbox"
                                        checked={this.state.agree}
                                        onChange={this.handleRejectChange}
                                    />
                                    <label htmlFor="agreeProxy">
                                        I agree to act as a proxy
                                    </label>

                                </div>
                                <div>
                                    <input
                                        id="disagreeProxy"
                                        name="reject" type="checkbox"
                                        checked={this.state.reject}
                                        onChange={this.handleRejectChange}
                                    />
                                    <label htmlFor="disagreeProxy">
                                        I do not agree to act as a proxy
                                    </label>

                                </div>
                            </div>
                            {this.state.errors &&
                                <div className="errorbox">
                                    {this.state.errors.proxy}
                                </div>
                            }
                            <div className="form-group submitbutton">
                                <input type="submit" value="Submit" className="btn" />
                            </div>
                        </form>

                        {clickLoad &&
                            <div className="loadingOverlay">
                                <div className="loader"></div>
                            </div>
                        }
                    </div>
                </div>
            </Fragment>

        );
    }

    checkPopulated() {
        const loggedIn = authService.loggedIn();
        if (loggedIn) {
            setTimeout(() => {
                if (!this.context.meeting.vmId) {
                    this.context.populateMeetingAndUser();
                }

            }, 1000);
        } else {
            history.push("/login/");
            let pathUrl = window.location.href;
            window.location.href = pathUrl;
        }
    }

    async populateProxyPollCard() {
        await this.context.getVoteData("proxy");
        const data = this.context.voteData;

        if (data) {
            this.setState({
                loading: false
            });
        }
    }
}

const ProxyDataTable = (props) => {
    const data = props.data;

    return (
        <table className="proxypollcarddata">
            <tbody>
                {data.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map(ballot =>
                    ballot.proxyVoting && <Fragment key={ballot.contestID}>
                        <tr className="d-none d-sm-table-row">
                            <th className="d-none d-sm-table-cell">

                            </th>
                            {ballot.proxyVoting.filter((a) => a.proxyVotingValue > 0).sort((a, b) => a.proxyVotingValue > b.proxyVotingValue ? 1 : -1).map(option =>
                                <th className="d-none d-sm-table-cell" key={option.proxyVotingValue}>
                                    {option.proxyVoteText}
                                </th>
                            )}
                            {ballot.proxyVoting.filter((a) => a.proxyVotingValue === 0).sort((a, b) => a.proxyVotingValue > b.proxyVotingValue ? 1 : -1).map(option =>
                                <th className="d-none d-sm-table-cell" key={option.proxyVotingValue}>
                                    {option.proxyVoteText}
                                </th>
                            )}
                        </tr>
                        {ballot.publishTypeId !== 2 &&
                            <tr >
                                <td className="d-block d-sm-table-cell proxyResTitle">
                                    {ballot.title}
                                </td>

                                {ballot.proxyVoting.filter((a) => a.proxyVotingValue > 0).sort((a, b) => a.proxyVotingValue > b.proxyVotingValue ? 1 : -1).map(option =>
                                    <td className="d-block d-sm-table-cell" key={option.proxyVotingValue}>
                                        <span className="d-sm-none">{option.proxyVoteText}: </span>
                                        {option.proxyVoteCount}
                                    </td>

                                )}
                                {ballot.proxyVoting.filter((a) => a.proxyVotingValue === 0).sort((a, b) => a.proxyVotingValue > b.proxyVotingValue ? 1 : -1).map(option =>
                                    <td className="d-block d-sm-table-cell" key={option.proxyVotingValue}>
                                        <span className="d-sm-none">{option.proxyVoteText}: </span>
                                        {option.proxyVoteCount}
                                    </td>

                                )}
                            </tr>
                        }
                        {ballot.publishTypeId !== 1 &&
                            <tr>
                                <td className="d-block d-sm-table-cell proxyResTitle">
                                    {ballot.title}
                                </td>

                                {ballot.proxyVoting.filter((a) => a.proxyVotingValue > 0).sort((a, b) => a.proxyVotingValue > b.proxyVotingValue ? 1 : -1).map(option =>
                                    <td className="d-block d-sm-table-cell" key={option.proxyVotingValue}>
                                        <span className="d-sm-none">{option.proxyVoteText}: </span>
                                        {option.proxyWeight}
                                    </td>

                                )}
                                {ballot.proxyVoting.filter((a) => a.proxyVotingValue === 0).sort((a, b) => a.proxyVotingValue > b.proxyVotingValue ? 1 : -1).map(option =>
                                    <td className="d-block d-sm-table-cell" key={option.proxyVotingValue}>
                                        <span className="d-sm-none">{option.proxyVoteText}: </span>
                                        {option.proxyWeight}
                                    </td>

                                )}
                            </tr>
                        }
                        <tr><td colSpan="42">&nbsp;</td></tr>
                    </Fragment>
                )}
            </tbody>
        </table>

    )
}


ProxyPollCard.contextType = UserContext;
