import React, { Component } from 'react';
import { redirect as Redirect } from 'react-router-dom';
import UserContext from './context/UserContext';

import authService from '../_services/AuthService';
import { withNavigation } from '../Common/WithNavigation';

class PageLoadMonitor extends Component {
    static displayName = PageLoadMonitor.name;


    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.checkLoggedIn();
        this.checkPopulated();
    }


    checkPopulated() {
        const loggedIn = authService.loggedIn();
        if (loggedIn) {
            this.setState({
                loggedIn: true
            })
            setTimeout(() => {
                if (!this.context.meeting.vmId) {
                    this.context.populateMeetingAndUser();
                }
            }, 4000);
        }
    }

    checkLoggedIn() {
        const loggedIn = authService.loggedIn();
        if (!loggedIn) {
            this.props.navigate("/login/")
            this.setState({
                loggedIn: false,
                loading: false
            })
        }
    }



    render() {
        return (<div />)
    }

}

export default withNavigation(PageLoadMonitor);

PageLoadMonitor.contextType = UserContext;