import React, { Fragment, useContext } from 'react';
import { LoginMenu } from './LoginMenu';//'./api-authorization/LoginMenu';
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faBell } from '@fortawesome/free-solid-svg-icons'
import UserContext from './context/UserContext';
import authService from '../_services/AuthService';


function ClientLogo(props) {
    const context = useContext(UserContext);
    if (context.fullState.contextLoading) {
        return (
            <Fragment>
                <div className="loader"></div>
            </Fragment>
        )
    } else {
        return (
            <img className="img-fluid Logo" alt="client logo" src={`data:image/jpeg;base64,${context.meeting.logoFile}`} />
        )
    }
}

export function NavTopBar(props) {
    const context = useContext(UserContext);
    let voteNotification = context.voteNotification;
    let loggedIn = authService.loggedIn();
    const { messageCount } = context;

    const getMessageNotification = () => {
        if (messageCount > 0)
            return `You have ${messageCount} unread message${messageCount !== 1 ? "s" : ""}`;
    }

    return (
        <Navbar collapseOnSelect expand="md" variant="light">
            <Container fluid>
                <Navbar.Brand href="#home"><ClientLogo /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">

                    <Nav className="me-auto">
                        <Nav.Item>
                            <NavLink className="nav-link" to="/">
                                Welcome
                            </NavLink>
                        </Nav.Item>

                        {((!context.fullState.attendanceStatus && context.switches.enableDocuments) || (context.fullState.attendanceStatus && context.switches.inRoomDocuments)) &&
                            <Nav.Item >
                                <NavLink to="/DocumentList" className="nav-link">Documents</NavLink>
                            </Nav.Item>
                        }

                        {((!context.fullState.attendanceStatus && context.switches.remoteVoting) || (context.fullState.attendanceStatus && context.switches.inRoomVoting)) && context.switches.enableVoting &&
                            <Nav.Item>
                                <NavLink to="/Contests" className="nav-link">Voting&nbsp;{voteNotification && context.fullState.openVoteCount > 0 && <Fragment><span title={"You have " + context.fullState.openVoteCount + " open contest" + (context.fullState.openVoteCount !== 1 ? "s" : "") + " to vote in"} className="menuVoteAlert">{context.fullState.openVoteCount}</span></Fragment>}</NavLink>
                            </Nav.Item>
                        }
                        {((!context.fullState.attendanceStatus && context.switches.results) || (context.fullState.attendanceStatus && context.switches.inRoomResults)) && context.switches.enableVoting &&
                            <Nav.Item>
                                <NavLink to="/Results" className="nav-link d-flex align-items-center">Results&nbsp;{context.fullState.resultNotification && <FontAwesomeIcon title="New results have been published" className="resultAlert" icon={faBell} size="xs"/>}</NavLink>
                            </Nav.Item>
                        }

                        {context.user.isMember && ((!context.fullState.attendanceStatus && context.switches.enableQA)
                            || (context.fullState.attendanceStatus && context.switches.inRoomChat)) &&
                            <Nav.Item>
                            <NavLink to="/Q&A" className="nav-link">Q&A&nbsp;
                                {messageCount > 0 ?
                                    <span title={getMessageNotification()} className="menuVoteAlert" >{messageCount}</span>
                                    :
                                    null}
                            </NavLink>
                            </Nav.Item>
                        }
                        {false && !context.fullState.attendanceStatus && context.switches.enableSurvey &&
                            <Nav.Item >
                                <NavLink to="/Survey" className="nav-link">Survey&nbsp;</NavLink>
                            </Nav.Item>
                        }

                        {((context.fullState.attendanceStatus && context.switches.inRoomAudiovisual) || (!context.fullState.attendanceStatus && context.switches.enableAudioVideo)) &&
                            <Nav.Item >
                                <NavLink to="/Video" className="nav-link">Max&nbsp;Video</NavLink>
                            </Nav.Item>
                        }

                        {context.fullState.attendanceStatus && context.switches.ticket &&
                            <Nav.Item >
                                <NavLink to="/Ticket" className="nav-link">Ticket</NavLink>
                            </Nav.Item>
                        }
                    </Nav>
                    <Nav>
                        {loggedIn &&
                            <Fragment>
                                <LoginMenu>
                                </LoginMenu>
                            </Fragment>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}