import React, { Component, useContext, Fragment, lazy, Suspense, useEffect } from 'react';
import UserContext from './context/UserContext';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { useAccordionButton } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import abstain from './abstain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import authService from '../_services/AuthService';
import CloseButton from './../components/CloseButton'
import { Sanitise } from '../Common/Sanitiser';
import { Chart as ChartJS, registerables } from 'chart.js';


const Bar = lazy(() => import("./DefaultBar"));
const BarFallBack = < div className="chartLoader" > <div className="bar bar1" /><div className="bar bar2" /><div className="bar bar3" /></div>

export default class Results extends Component {
    static displayName = Results.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            voteStatus: null,
            resultData: []
        };
    }

    componentDidMount() {
        document.title = "CESJoinIN - Results"
        if (this.context.fullState.contextLoading === false) {
            this.populateResults();
        }
        this.setState({
            contextLoading: this.context.fullState.contextLoading,
            isPaneOpen: true
        })
        this.setState({ voteStatus: this.context.fullState.voteStatus });
        this.context.setResultNotification(false);
        ChartJS.register(...registerables);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate() {

        if (this.state.isPaneOpen === false && this.context.fullState.resultsClicked === true) {
            this.context.resultsNavBarClicked(false);
            this.setState({ isPaneOpen: true });
        }

        if (this.state.contextLoading !== this.context.fullState.contextLoading) {
            this.populateResults();
            this.setState({
                contextLoading: this.context.fullState.contextLoading
            })
        }

        if (this.context.fullState.voteStatus !== this.state.voteStatus) { //these conditions will need tweaking if there are multiple active resolutions with different statuses
            if (this.context.fullState.voteStatus === 'PublishResult') {
                this.setState({
                    loading: true
                });
                this.populateResults();
            }
            this.setState({ voteStatus: this.context.fullState.voteStatus });
        }
    }

    render() {
        let List = this.state.resultData.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
        let contents = this.state.loading
            ? <div className="loader"></div>
            : <PopResults List={List} />

        if (this.context.fullState.contextLoading === true) {
            return (
                <div className="cloud photoLoaderHolder">
                    <div className="loader"></div>
                </div>
            )
        }

        return (
            <div className="row scrollFix">
                <div className="cloud scrollContainer">
                    {contents}
                </div>
            </div>
        );

    }



    async populateResults() {
        const token = authService.getToken();
        const response = await fetch('VMGetPublishedContestList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        if (data) {
            this.setState({
                resultData: data,
                loading: false
            });

        }
    }
}

//Main page content with text for each resolution - doesn't hold results and graph, see children
class PopResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: 0
        };
    }

    setActiveItem = (newValue) => {
        if (this.state.activeItem !== newValue) {
            this.setState({
                activeItem: newValue
            })
        }
    }

    render() {
        let List = this.props.List.filter(c => c.contestStatusId === 7);
        if (List.length === 0) {
            return (
                <>
                    <div className="d-flex justify-content-between">
                        <h2>Results</h2>
                        <CloseButton />
                    </div>
                    <p>There are no results to display.</p>
                </>
            )
        }
        return (
            <Fragment>
                <div>
                    <div className="d-flex justify-content-between">
                        <h2>Results</h2>
                        <CloseButton />
                    </div>
                    <div className="ResultsContainer">

                        <div className="row">
                            <Accordion className="accordionFullWidth">
                                {List.map((listResults) =>
                                    <Fragment key={listResults.displayOrder}>
                                        <div className="cloudres" >
                                            <Card.Header>
                                                <div className="endButton">
                                                    <div>
                                                        {listResults.contestName}
                                                    </div>
                                                    <div>
                                                        <ContextAwareToggle eventKey={listResults.contestId} setActive={this.setActiveItem} />
                                                    </div>
                                                </div>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={listResults.contestId}>
                                                <Card.Body>
                                                    <div dangerouslySetInnerHTML={Sanitise(listResults.additionalText)} />
                                                    {listResults.contestTypeId === 1 && <SingleResult ballot={listResults} isActive={this.state.activeItem == listResults.contestId} />}
                                                    {listResults.contestTypeId === 2 && <SingleXvoteResult ballot={listResults} isActive={this.state.activeItem == listResults.contestId} />}
                                                    {listResults.contestTypeId === 3 && <SinglePreferentialResult ballot={listResults} isActive={this.state.activeItem == listResults.contestId} />}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </div>
                                    </Fragment>
                                )}
                            </Accordion>
                        </div>
                    </div >
                </div >
            </Fragment>
        );
    }
}


//Individual result graph and table
export class SingleResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            singleResult: {},
            totalVotes: 0,
            totalOfficialWeight: 0,
            width: 500,
            margin: { top: 20, right: 20, bottom: 30, left: 40 },
            ballot: { votingOptions: null, finalResult: null },
            loading: true
        };

    }

    getOfficialVoteCount = (countem) => {
        let n = 0
        countem.forEach(function (result) {
            if (!abstain(result.votingOption)) {
                n = n + result.voteResult;
            }
        });
        return n;
    }

    getOfficialVoteWeight = (countem) => {
        let n = 0
        countem.forEach(function (result) {
            if (!abstain(result.votingOption)) {
                n = n + result.weightedResult;
            }
        });
        return n;
    }

    getVoteCount = (countem) => {
        let n = 0
        countem.forEach(function (result) {
            n = n + result.voteResult;
        });
        return n;
    }

    returnPercentage = (value) => {
        let base = this.state.totalVotes;
        if (base === 0) {
            base = 1;
        }
        let result = (value / base) * 100;
        return result;
    }

    returnOfficialPercentage = (value) => {
        let base = this.state.totalOfficialVotes;
        if (base === 0) {
            base = 1;
        }
        let result = (value / base) * 100;
        return result.toFixed(2).toLocaleString();
    }

    returnWeightedPercentage = (value) => {
        let base = this.state.totalOfficialWeight;
        if (base === 0) {
            base = 1;
        }
        let result = (value / base) * 100;
        return result.toFixed(2).toLocaleString();
    }

    returnLocalNumberString = (value) => {
        return value.toLocaleString();
    }

    componentDidMount() {
        let ballot = this.props.ballot;
        this.setState({
            ballot: ballot
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive !== prevProps.isActive && !this.state.singleResult.length) {
            this.getResultData(this.props.ballot.contestId);
        }
    }

    getResultData = async (contestId) => {
        let request = {
            ContestId: contestId
        }
        const token = authService.getToken();
        const response = await fetch('VMGetPublishedContestResult', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(request)
        });

        const data = await response.json();

        if (data) {
            this.setState({
                singleResult: data,
                totalVotes: this.getVoteCount(data),
                totalOfficialVotes: this.getOfficialVoteCount(data),
                totalOfficialWeight: this.getOfficialVoteWeight(data),
                loading: false
            })
        }

    }

    render() {
        let results = [].concat(this.state.singleResult).sort((a, b) => (a.voteOptionDisplayOrder - b.voteOptionDisplayOrder));
        let publishTypeId = this.state.ballot.publishTypeId;
        let data = [];
        let dataw = [];
        let labels = [];
        if (this.state.loading) {
            return (
                <Fragment>
                    <div className='loader' />
                </Fragment>
            )
        }
        else if (this.state.singleResult[0].declaredResultId > 0) {
            return (
                <Fragment>
                    <div>Result: <strong>{this.state.singleResult[0].declaredResult}</strong></div>
                </Fragment>
            )
        }


        else {
            results.forEach((val) => {
                // Don't display abstain options in the charts as they are confusing - Phil W agreed with Martin Craig 10th March 2021
                if (!abstain(val.votingOption)) {
                    labels = labels.concat(val.votingOption);
                    data = data.concat(this.returnOfficialPercentage(val.voteResult));
                    dataw = dataw.concat(this.returnWeightedPercentage(val.weightedResult));
                }
            });
            let chartData = {
                labels: labels,
                datasets: [
                    {
                        label: this.state.ballot.contestName,
                        backgroundColor: 'rgba(255,99,132,0.5)',
                        borderColor: 'rgba(255,99,132,1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255,99,132,0.9)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        scaleOverride: true,
                        data: publishTypeId === 1 ? data : dataw
                    }]
            };

            let chartDataDual = {
                labels: labels,
                datasets: [
                    {
                        label: 'Standard %',
                        backgroundColor: 'rgba(255,99,132,0.5)',
                        borderColor: 'rgba(255,99,132,1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(255,99,132,0.9)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        scaleOverride: true,
                        data: data
                    },
                    {
                        label: 'Weighted %',
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(54,162,235,0.9)',
                        hoverBorderColor: 'rgba(54, 162, 235, 1)',
                        scaleOverride: true,
                        data: dataw
                    }
                ]
            };


            let chartOptions = {
                legend: {
                    display: false
                },
                scales: {
                    y: {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            display: true,
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                            stepSize: 20,
                            callback: function (value, index, values) {
                                if (Math.floor(value) === value) {
                                    return value + '%';
                                }
                            }
                        }
                    }
                },
                maintainAspectRatio: true
            }

            if (this.props.ballot.length > 0 && this.state.totalVotes === 0) {
                this.getVoteCount(this.props.resultBlock)
            }

            return (
                <Fragment>
                    <div className="openResDashboardb">
                        <div className="row">
                            <div className="col-12" >
                                <div className="sr-only" id={"labelforchartID-" + this.state.ballot.contestID}>Chart of results for {this.state.ballot.title}
                                    {chartData.labels.map((label, i) => {
                                        let result = <span key={"result" + this.state.ballot.contestID + "key" + i}>Option {label}, result {chartData.datasets[0].data[i]}% </span>
                                        return result
                                    })}


                                </div>
                                <figure className="barChart" id={"chartID-" + this.state.ballot.contestID} aria-describedby={"labelforchartID-" + this.state.ballot.contestID}>
                                    <Suspense fallback={BarFallBack}>
                                        <Bar
                                            width={this.state.width}
                                            height={150}
                                            data={publishTypeId === 3 ? chartDataDual : chartData}
                                            options={chartOptions}
                                        />
                                    </Suspense>
                                </figure>
                            </div>

                            <div className="col-12">
                                <div className="cloudGrid">
                                    <table className="table table-bordered">
                                        <caption className="sr-only">Table of results for {this.state.ballot.title}</caption>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Option</th>
                                                {(publishTypeId === 1 || publishTypeId === 3) &&
                                                    <Fragment>
                                                        <th scope="col">Count</th>
                                                        <th scope="col">Count %</th>
                                                    </Fragment>
                                                }
                                                {(publishTypeId === 2 || publishTypeId === 3) &&
                                                    <Fragment>
                                                        <th scope="col">Weight</th>
                                                        <th scope="col">Weight %</th>
                                                    </Fragment>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {results.map((result) =>
                                                <tr key={result.votingValue}>
                                                    <td>{abstain(result.votingOption) ?
                                                        <Fragment>({result.votingOption})</Fragment>
                                                        : <Fragment> {result.votingOption}</Fragment>
                                                    }</td>
                                                    {(publishTypeId === 1 || publishTypeId === 3) &&
                                                        <Fragment>
                                                            <td>{abstain(result.votingOption) ?
                                                                <Fragment>({this.returnLocalNumberString(result.voteResult)})</Fragment>
                                                                : <Fragment> {this.returnLocalNumberString(result.voteResult)}</Fragment>
                                                            }</td>
                                                            <td>{abstain(result.votingOption) ? '' :
                                                                <Fragment>
                                                                    {this.returnOfficialPercentage(result.voteResult)}%
                                                                </Fragment>
                                                            }</td>
                                                        </Fragment>
                                                    }
                                                    {(publishTypeId === 2 || publishTypeId === 3) &&
                                                        <Fragment>
                                                            <td>{abstain(result.votingOption) ?
                                                                <Fragment>({this.returnLocalNumberString(result.weightedResult)})</Fragment>
                                                                : <Fragment> {this.returnLocalNumberString(result.weightedResult)}</Fragment>
                                                            }</td>
                                                            <td>{!abstain(result.votingOption) &&
                                                                <Fragment>
                                                                    {this.returnWeightedPercentage(result.weightedResult)}%
                                                                </Fragment>
                                                            }</td>
                                                        </Fragment>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            )
        }
    }
}


export class SingleXvoteResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ballot: {},
            singleResult: {},
            loading: true
        };

    }
    returnLocalNumberString(value) {
        return value.toLocaleString();
    }

    componentDidMount() {
        let ballot = this.props.ballot;
        this.setState({
            ballot: ballot
        })
    }


    componentDidUpdate(prevProps) {
        if (this.props.isActive !== prevProps.isActive && !this.state.singleResult.length) {
            this.getResultData(this.props.ballot.contestId);
        }
    }

    getResultData = async (contestId) => {
        let request = {
            ContestId: contestId
        }
        const token = authService.getToken();
        const response = await fetch('VMGetPublishedContestResult', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(request)
        });

        const data = await response.json();

        if (data) {
            let _data = this.sortResultData(data);
            this.setState({
                singleResult: _data,
                loading: false
            })
        }
    }

    sortResultData = (data) => {
        let _data = data.sort((a, b) => a.displayOrder < b.displayOrder ? 1 : -1).sort((a, b) => a.voteResult < b.voteResult ? 1 : -1);
        if (this.props.ballot.publishTypeId !== 1) {
            _data = _data.sort((a, b) => a.weightedResult < b.weightedResult ? 1 : -1);
        }
        return _data;
    }



    render() {
        let results = this.state.singleResult;
        let publishTypeId = this.props.ballot.publishTypeId;
        if (this.state.loading) {
            return (
                <Fragment>
                    <div className='loader' />
                </Fragment>
            )
        }
        if (!this.state.loading) {
            return (
                <Fragment>
                    <div className="openResDashboardb">
                        <div className="row">
                            <div className="col-12">
                                {this.state.ballot.showNumSeats &&
                                    <div className="seatDisplay">
                                        There {this.state.ballot.contestSeats !== 1 ? 'are' : 'is'} {this.state.ballot.contestSeats} {this.state.ballot.votingFor}{this.state.ballot.contestSeats !== 1 && 's'} to elect.
                                    </div>
                                }
                                <div className="cloudGrid">
                                    <table className="table table-bordered">
                                        <caption className="sr-only">Table of results for {this.state.ballot.title}</caption>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">{this.state.ballot.votingFor[0].toUpperCase() + this.state.ballot.votingFor.slice(1)}</th>
                                                {publishTypeId !== 2 &&
                                                    <th scope="col">Count</th>
                                                }
                                                {publishTypeId !== 1 &&
                                                    <th scope="col">Weight</th>
                                                }

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {results.map((result) =>
                                                <tr key={result.votingValue}>
                                                    <td> {result.votingOption}</td>
                                                    {publishTypeId !== 2 &&
                                                        <td> {this.returnLocalNumberString(result.voteResult)}</td>
                                                    }
                                                    {publishTypeId !== 1 &&
                                                        <td> {this.returnLocalNumberString(result.weightedResult)}</td>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            )
        }
    }
}

export class SinglePreferentialResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ballot: {},
            singleResult: {},
            loading: true
        };

    }
    returnLocalNumberString(value) {
        return value.toLocaleString();
    }

    componentDidMount() {
        let ballot = this.props.ballot;
        this.setState({
            ballot: ballot
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive !== prevProps.isActive && !this.state.singleResult.length) {
            this.getResultData(this.props.ballot.contestId);
        }
    }

    getResultData = async (contestId) => {
        let request = {
            ContestId: contestId
        }
        const token = authService.getToken();
        const response = await fetch('VMGetPublishedContestResult', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(request)
        });

        const data = await response.json();

        if (data) {
            this.setState({
                singleResult: data,
                loading: false
            })
        }
    }

    render() {
        let results = this.state.singleResult;
        if (this.state.loading) {
            return (
                <Fragment>
                    <div className='loader' />
                </Fragment>
            )
        }
        if (!this.state.loading) {
            return (
                <Fragment>
                    <div className="openResDashboardb">
                        <div className="row">
                            <div className="col-12">
                                {this.state.ballot.showNumSeats &&
                                    <div className="seatDisplay">
                                        <p>There {this.state.ballot.contestSeats !== 1 ? 'are' : 'is'} {this.state.ballot.contestSeats} {this.state.ballot.votingFor}{this.state.ballot.contestSeats !== 1 && 's'} to elect.</p>
                                    </div>
                                }
                                <div className="cloudGrid">
                                    <p>The following {this.state.ballot.votingFor}{this.state.ballot.contestSeats !== 1 && 's'} {this.state.ballot.contestSeats !== 1 ? 'have' : 'has'} been elected:</p>
                                    <div className="mx-3">
                                        {results.filter(a => a.voteResult > 0).sort((a, b) => a.candidateForename.localeCompare(b.candidateForename)).sort((a, b) => a.candidateSurname.localeCompare(b.candidateSurname)).map(result =>
                                            <div key={result.votingValue} >
                                                {result.candidateSurname.toUpperCase()}{result.candidateSurname.length > 0 && result.candidateForename.length > 0 ? "," : ""} {result.candidateForename}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            )
        }
    }
}
//accordion functionality
function ContextAwareToggle({ eventKey, setActive, callback }) {
    const switches = useContext(UserContext);
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;


    //scroll window to top of selected result
    useEffect(() => {
        if (isCurrentEventKey) {
            setActive(eventKey);
            setTimeout(function () {
                document.getElementById(`button${eventKey}`).parentElement.parentElement.parentElement.parentElement.scrollIntoView();


            }, 350);
        }
    }, [isCurrentEventKey])



    const baseStyle = {
        //color: 'white',
        border: 0,
        width: '120px'
    }
    const activeStyle = {
        //color: 'white',
        border: 0,
        width: '120px'
    }

    const buttonOpen = () => {
        return (
            <div>
                Show result&nbsp;
                <FontAwesomeIcon icon={faChevronDown} />
            </div>
        )
    }
    const buttonClose = () => {
        return (
            <div>
                Hide result&nbsp;
                <FontAwesomeIcon icon={faChevronUp} />
            </div>
        )
    }

    return (
        <button
            id={"button" + eventKey}
            type="button"
            style={isCurrentEventKey ? activeStyle : baseStyle}
            onClick={decoratedOnClick}
            className="btn btn-sm expandMoreBtn"
            aria-label={isCurrentEventKey ? "Collapse to hide result" : "Expand to show result"}
        >
            {isCurrentEventKey ? buttonClose() : buttonOpen()}
        </button>
    );
}

Results.contextType = UserContext;
PopResults.contextType = UserContext;