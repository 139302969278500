import React, { useState } from 'react';
import OpenChatContext from './OpenChatContext';
import { OpenChatNewPost, OpenChatNewLike, OpenChatGetMyActions, DeleteOwnPost, OpenChatGetEmojis, OpenChatGetPagedPosts, OpenChatGetSinglePost, OpenChatAcceptTerms, OpenChatEditPost } from '../../JsFunctions/APIFunctions';
import { OpenChatGetModeratorFlagOptions, OpenChatNewFlag } from '../../JsFunctions/ModeratorFlagFunctions';

export function OpenChatContextProvider(props) {
    const [localState, setLocalState] = useState({
        Posts: [],
        Quoted: [],
        Likes: [],
        Flags: [],
        EmojiList: [],
        AcceptTerms: 0,
        Muted: 0,
        LoadingActions: true
    });
    const [replyTarget, setReplyTarget] = useState(0);
    const [minCurrentPost, setMinCurrentPost] = useState(0);
    const [minVisiblePost, setMinVisiblePost] = useState(0);

    //Posts from SignalR
    const SignalRUpdatePosts = (newdata) => {
        MergeNewPostData("Posts", newdata);
    }

    //Quoted posts not in existing data
    const GetSinglePost = async (postId) => {
        let newdata = await OpenChatGetSinglePost(postId);
        MergeNewPostData("Quoted", newdata);
    }

    const AddNewPost = async (event, text) => {
        event.preventDefault();
        let postResponse = await OpenChatNewPost(replyTarget, text);
        if (postResponse && postResponse.statusId === 200) {
            //if successful and data not yet update by SR, add to local list of posts
            let posts = [...localState.Posts];
            const index = posts.findIndex(post => post.PostId === postResponse.PostId);
            if (index === -1) {
                let newPost = {
                    PostId: postResponse.returnValue,
                    PostContent: text,
                    IsPostOwner: true,
                    Like: 0,
                    Dislike: 0,
                    DateTimeNow: Date.now(),
                    ParentPostId: replyTarget,
                    CreatedDateTime: Date.now(),
                    UserName: props.userName
                }
                posts.push(newPost);
                updateState("Posts", posts);
            }
            setReplyTarget(0);
            return true;
        } else if (postResponse && postResponse.statusId === 403 && postResponse.returnValue === 2) {
            //profanity filter
            return "profanity";
        }
        else {
            //add failure notification
            return false;
        }
    }


    const EditPost = async (event, postId, text) => {
        event.preventDefault();
        let postResponse = await OpenChatEditPost(postId, text);
        if (postResponse && postResponse.statusId === 200) {
            let newPost = {
                PostId: postId,
                PostContent: text,
                Edited: true
            }
            MergeNewPostData("Posts", [newPost])
            return true;
        } else if (postResponse && postResponse.statusId === 403 && postResponse.returnValue === 2) {
            //profanity filter
            return "profanity";
        }
        else {
            //add failure notification
            return false;
        }
    }

    //Get paged posts
    const GetOpenChatPosts = async (targetId) => {
        let posts = await OpenChatGetPagedPosts(targetId ? targetId : minCurrentPost);
        if (posts && posts !== "Error" && posts.length > 0 && !!posts[0].PostId) {
            MergeNewPostData("Posts", posts);
            const min = posts.reduce((prev, curr) => prev.PostId < curr.PostId ? prev : curr);
            if (min) {
                setMinCurrentPost(min.PostId);
            }
            let visibleCount = posts //.filter(a => !!!a.Hidden)
            if (visibleCount.length > 0) {
                const minVisible = visibleCount.reduce((prev, curr) => prev.PostId < curr.PostId ? prev : curr);
                setMinVisiblePost(minVisible.PostId);
            }
        }
    }

    const DeletePost = async (targetId) => {
        let response = await DeleteOwnPost(targetId);
        if (response && response.statusId === 200) {
            //remove post from state
            let posts = localState.Posts;
            posts.forEach(post => {
                if (post.PostId === targetId) {
                    post.Hidden = true;
                    post.PostContent = "This message has been deleted by the author"
                }
            })
            updateState("Posts", posts);
            //add success notification
        } else {
            //add failure notification
            return false;
        }

    }

    const MergeNewPostData = (list, postData) => {
        let statePosts = localState[list] ? [...localState[list]] : [];
        postData.forEach((updatePost) => {
            const index = statePosts.findIndex(post => post.PostId === updatePost.PostId);
            if (index >= 0) {
                let combinedPost = { ...statePosts[index], ...updatePost };
                statePosts[index] = combinedPost;
            }
            else {
                statePosts.push(updatePost);
            }
        });
        statePosts.sort((a, b) => a.PostId - b.PostId);
        updateState(list, statePosts);
    }

    const updateState = (key, value) => {
        setLocalState(localState => ({ ...localState, [key]: value }));
    }

    const AddNewLike = async (targetId, actionType) => {
        let postResponse = await OpenChatNewLike(targetId, actionType);
        if (postResponse && postResponse !== "Error" && postResponse.statusId === 200) {
            let likeList = [...localState.Likes];
            if (likeList) {
                if (likeList.find(a => a.PostId === targetId)) {
                    likeList.forEach(obj => {
                        if (obj.PostId === targetId) { obj.ActionType = actionType }
                    });
                    updateState("Likes", likeList);
                } else {
                    let obj = { "PostId": targetId, "ActionType": actionType }
                    likeList.push(obj)
                    updateState("Likes", likeList);
                }
            }
        }
        return postResponse;
    }

    const AddNewFlag = async (targetId, actionType) => {
        let response = "";
        let flagList = localState.Flags;
        if (flagList.find(a => a.PostId === targetId)) {
            response = "Reject"
        } else {
            let postResponse = await OpenChatNewFlag(targetId, actionType);
            if (postResponse && postResponse !== "Error" && postResponse.statusId === 200) {
                let obj = { "PostId": targetId, "ActionType": actionType }
                flagList.push(obj)
                updateState("Flags", flagList);
                response = "Success"
            } else if (postResponse && postResponse !== "Error" && postResponse.statusId === 403) {
                response = "Reject"
            } else {
                response = "Error"
            }
        }
        return response;
    }

    const AcceptTerms = async () => {
        let response = "";
        let postResponse = await OpenChatAcceptTerms();
        if (postResponse && postResponse !== "Error" && postResponse.statusId === 200) {
            response = "Success";
            updateState("AcceptTerms", 1);
        } else if (postResponse && postResponse !== "Error" && postResponse.statusId === 403) {
            response = "Reject"
        } else {
            response = "Error"
        }

        return response;
    }

    const GetOpenChatMyActions = async () => {
        let actions = await OpenChatGetMyActions();
        if (actions && actions !== "Error") {
            let likes = actions[0].Like;
            if (likes && likes.length > 0) {
                updateState('Likes', likes);
            }
            let flags = actions[0].Flag;
            if (flags && flags.length > 0) {
                updateState('Flags', flags);
            }
            updateState('AcceptTerms', actions[0].Terms);
            updateState('Muted', actions[0].Muted);
            updateState('LoadingActions', false);
        }
    }

    const GetModeratorFlagOptions = async () => {
        let options = await OpenChatGetModeratorFlagOptions();
        if (options !== "Error") {
            updateState("FlagOptions", options);
        }
    }

    const SetReplayTarget = (target) => {
        setReplyTarget(target)
    }

    const GetOpenChatGetEmojis = async () => {
        let EmojiList = await OpenChatGetEmojis();
        if (EmojiList && EmojiList !== "Error" && EmojiList.length > 0) {
            updateState("EmojiList", EmojiList);
        }
    }

    return (
        <OpenChatContext.Provider value={{
            //state
            contextState: localState,
            replyTarget: replyTarget,
            minVisiblePost: minVisiblePost,


            //functions
            OpenChatGetAllPosts: GetOpenChatPosts,
            OpenChatGetSinglePost: GetSinglePost,
            OpenChatGetMyActions: GetOpenChatMyActions,
            GetModeratorFlagOptions: GetModeratorFlagOptions,
            OpenChatNewPost: AddNewPost,
            OpenChatEditPost: EditPost,
            OpenChatDeletePost: DeletePost,
            OpenChatNewLike: AddNewLike,
            OpenChatNewFlag: AddNewFlag,
            SignalRUpdatePosts: SignalRUpdatePosts,
            setReplyTarget: SetReplayTarget,
            OpenChatGetEmojis: GetOpenChatGetEmojis,
            AcceptTerms: AcceptTerms
        }}
        >
            {props.children}
        </OpenChatContext.Provider>

    )
}

