import React from 'react';

export function Footer(props) {
    return (
        <footer>
            <div className="footer px-3 py-2 d-flex small">
                <div className="flex-grow-1 col-md">
                    Civica Election Services
                </div>
                <div className="col-md-auto text-end">
                    <a href="../Privacy" target="_blank">Privacy Policy</a> | <a href="../Help" target="_blank">Help</a> | <a href="../Accessibility" target="_blank">Accessibility</a>
                </div>
            </div>
        </footer>
    );
}
