
import React, { Component } from 'react';
import UserContext from './UserContext';
import authService from '../../_services/AuthService';
import {jwtDecode as jwt} from 'jwt-decode';

const initialState = {
    user: {
        userName: null,
        userID: null,
        role: null,
        isProxy: false,
        isVoter: false,
        isMember: false,
        hasAppointedProxy: false,
        hasAcceptedProxy: false,
        hasPreVote: null,
        hasPollCard: null,
        test: null
    },
    meeting: {
        vmId: null,
        jobNo: null,
        clientColour: null
    },
    switches: {},
    messageCount: 0,
    messageAlert: false,
    apiMeetingData: [],
    voteNotification: false,
    resultNotification: false,
    openVoteCount: 0,
    voteStatus: '',
    contextLoading: true,
    hubConnection: null,
    resultsClicked: false,
    proxyStatusLoading: true,
    voteDataLoading: false,
    attendingSubmitted: null,
    rescindSubmitted: null,
    pollCardSubmitted: null,
    forceLogoutMessage: null,
    forceLogout: false,
    voteData: {},
    resultData: {},
    lastDataCallTime: null,
    lastDataCallType: null,
    attendanceStatus: null,
    assignedPollCardNumber: null,
    voted: false
}

export class UserContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                userName: null,
                userID: null,
                role: null,
                isProxy: false,
                isVoter: false,
                isMember: false,
                hasAppointedProxy: false,
                hasAcceptedProxy: false,
                hasPreVote: null,
                vmID: null,
                test: null
            },
            meeting: {
                vmId: null,
                jobNo: null,
                clientColour: null
            },
            uid: null,
            switches: {},
            messageCount: 0,
            messageAlert: false,
            apiMeetingData: [],
            voteNotification: false,
            resultNotification: false,
            openVoteCount: 0,
            voteStatus: '',
            contextLoading: true,
            hubConnection: null,
            resultsClicked: false,
            proxyStatusLoading: true,
            attendingSubmitted: false,
            rescindSubmitted: null,
            pollCardSubmitted: null,
            forceLogoutMessage: null,
            forceLogout: false,
            voteData: {},
            resultData: {},
            lastDataCallTime: null,
            lastDataCallType: null,
            attendanceStatus: null,
            assignedPollCardNumber: null,
            voted: false,
            qAState: {
                selectedTab: "",
                typedMessage: ""
            },
            surveyPopup: false,
            keyIncrement: 0
        }

    }

    changeTheme = () => {
        document.documentElement.style.setProperty('--main-client-color', this.state.meeting.primaryBrandColour);
        if (this.state.switches.enableDarkTheme) {
            document.documentElement.style.setProperty('--main-theme-color', "black");
        }
    }

    resultsNavBarClicked = (value) => {
        if (value !== this.state.resultsClicked) {
            this.setState({ resultsClicked: value })
        }
    }

    setMessageValues = (alert) => {
        this.setState({
            messageAlert: alert,
            messageCount: alert ? this.state.messageCount + 1 : 0
        });
    }

    setVoteNotification = (value) => {
        this.setState({ voteNotification: value });
    }

    setResultNotification = (value) => {
        this.setState({ resultNotification: value });
    }

    //used by proxy router and related pages.
    setArbStatus = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    clearContext = () => {
        this.setState({
            user: {
                userName: null,
                userID: null,
                role: null,
                isProxy: false,
                isVoter: false,
                isMember: false,
                hasAppointedProxy: false,
                hasAcceptedProxy: false,
                hasPreVote: null,
                hasPollCard: null,
                test: null
            },
            meeting: {
                vmId: null,
                jobNo: null,
                clientColour: null
            },
            uid: null,
            switches: {},
            messageCount: 0,
            messageAlert: false,
            apiMeetingData: [],
            voteNotification: false,
            resultNotification: false,
            openVoteCount: 0,
            voteStatus: '',
            contextLoading: true,
            hubConnection: null,
            resultsClicked: false,
            proxyStatusLoading: true,
            attendingSubmitted: null,
            rescindSubmitted: null,
            pollCardSubmitted: null,
            forceLogoutMessage: null,
            forceLogout: false,
            voteData: {},
            resultData: {},
            lastDataCallTime: null,
            lastDataCallType: null,
            attendanceStatus: null,
            assignedPollCardNumber: null,
            voted: false,
            keyIncrement: 0
        });
    }

    setVoteStatus = (value) => {
        this.setState({ voteStatus: value });
    }

    locationContestSelector = () => {
        //get the right contests for the page you're looking at.
        var location = window.location.pathname;
        var response;
        if (location.includes("/Contests") || location.includes("/DocumentList")) {
            response = 2;
        } else if (location.includes("/Login") || location.includes("/Proxy")) {
            response = 1;
        } else if (location.includes("/Results")) {
            response = 5
        } else {
            response = 2
        }

        return response;
    }

    setSurveyPopup = () => {
        this.setState({
            surveyPopup: true
        })
    }

    getVoteData = async (source) => {
        var timeStamp = new Date().getTime();
        var isSRsource = false;

        var ContestSelector;
        switch (source) {
            case "proxy":
                ContestSelector = 1;
                break;
            case "SrProxy":
                ContestSelector = 1;
                isSRsource = true;
                break;
            case "voting":
                ContestSelector = 2;
                break;
            case "SrVoting":
                ContestSelector = 2;
                isSRsource = true;
                break;
            case "results":
                ContestSelector = 3;
                break;
            case "SrResult":
                ContestSelector = 3;
                isSRsource = true;
                break;
            case "SrReset":
                ContestSelector = 4;
                isSRsource = true;
                break;
            case "ContextPopulate":
                ContestSelector = this.locationContestSelector();
                break;
            default:
                ContestSelector = 4; //get everything not closed
        }

        if (isSRsource || this.state.voted || timeStamp - this.state.lastDataCallTime > 5000 || ContestSelector !== this.state.lastDataCallType) {
            await this.setState({
                voteDataLoading: true
            })
            var request = {
                ContestSelector: ContestSelector
            }
            const token = authService.getToken();
            const response = await fetch('VMGetAllContestsforVoter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(request)
            });

            const data = await response.json();

            if (data) {
                this.setState({
                    lastDataCallTime: timeStamp,
                    lastDataCallType: ContestSelector
                })
                if (ContestSelector !== 3) {
                    this.setState({
                        voteData: data,
                        voted: false
                    })

                }

                if (ContestSelector >= 3) {
                    this.setState({
                        resultData: data
                    })
                }

                var activeContest = data.filter(ballot => ballot.contestStatusId === 4 || ballot.contestStatusId === 3 || ballot.contestStatusId === 2);
                var activeCount = activeContest.length;

                var opencontest = data.filter(ballot => (ballot.contestStatusId === 3
                    && (parseInt(ballot.attendeesVote) === 0 || ballot.attendeesVote === null)
                    && ballot.canVote === true
                    && this.state.user.isVoter === true
                    && ballot.hasProxy === false));
                var openCount = opencontest.length;

                var pendingcontest = data.filter(ballot => ballot.contestStatusId === 5 || ballot.contestStatusId === 7);
                var pendingCount = pendingcontest.length;

                if (activeCount > 0) {
                    this.setState({
                        voteStatus: "active",
                        voteNotification: true,
                        openVoteCount: openCount
                    })
                } else if (pendingCount > 0) {
                    this.setState({
                        voteStatus: "pending",
                        voteNotification: false,
                        openVoteCount: openCount
                    })
                } else {
                    this.setState({
                        voteStatus: "nothingyet",
                        voteNotification: false,
                        openVoteCount: openCount
                    })
                }
                this.setState({
                    voteDataLoading: false
                })
            } else {
                this.setState({
                    voteStatus: "nothingyet",
                    voteNotification: false,
                    openVoteCount: 0,
                    voteDataLoading: false
                })
            }

        }
    }

    // Used to identify this session when seeing if others need logging out
    uidGetSet = () => {
        if (this.state.uid == null) {
            var crypto = window.crypto;
            var typedArray = new Uint8Array(16);
            crypto.getRandomValues(typedArray);
            var str = new TextDecoder().decode(typedArray);
            this.setState({ uid: str });
            return str;
        } else return this.state.uid;
    }

    setUserData = async (vmID, vmUser, role, userID, test) => {
        var userName = vmUser.username;
        var setVoter;
        var setProxy;
        var setMember;
        if (role.toUpperCase() === "VOTINGMEMBER") {
            if (this.state.proxyStatusLoading === true) {
                await this.checkProxyStatus(vmID, userID);
            }

            if (this.state.user.hasPreVote === true) {
                setVoter = false;
            } else {
                setVoter = true;
            }
            setMember = true;
        }
        if (role.toUpperCase() === "NONVOTINGMEMBER") {
            setVoter = false;
            setMember = true;
        }
        if (role.toUpperCase() === "GUEST") {
            setVoter = false;
            setMember = false;
        }

        var oldUserState = this.state.user;
        oldUserState.isVoter = setVoter;
        oldUserState.isMember = setMember;
        oldUserState.userName = userName;
        oldUserState.userID = userID;
        oldUserState.role = role;
        oldUserState.vmID = vmID;
        oldUserState.test = test;
        this.setState({
            user: oldUserState
        })
    }

    checkAttendanceStatus = async () => {
        //check whether the attendee is in the validated attendee table
        const token = authService.getToken();
        await fetch('VMGetAttendanceStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't get attendance details");
            }
            return response.json();
        }).then(data => {
            this.setState({
                attendanceStatus: data.attendanceStatus,
                assignedPollCardNumber: data.pollCardNumber
            })
            if (data.attendanceStatus && data.pollCardNumber && data.pollCardNumber.length > 0) {
                var oldUserState = this.state.user;
                oldUserState.hasPollCard = true
                this.setState({
                    user: oldUserState
                })
            }
        })
    }

    checkProxyStatus = async (vmID, scID) => {

        const token = authService.getToken();

        await fetch('VMProxyPageRouting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't get proxy details");
            }
            return response.json();
        }).then(data => {

            var oldUserState = this.state.user;
            oldUserState.isProxy = data.isProxy;
            oldUserState.hasAppointedProxy = data.hasAppointedProxy;
            oldUserState.hasAcceptedProxy = data.hasAcceptedProxy;
            oldUserState.hasPreVote = data.hasPreVote;


            this.setState({
                user: oldUserState,
                proxyStatusLoading: false
            });
        });
    }

    getProxyStatus = async () => {
        await this.checkProxyStatus(parseInt(this.state.meeting.vmId), parseInt(this.state.user.userID));
    }

    submitPreVoteRescind = async (value) => {
        const token = authService.getToken();
        var request = {
            Rescind: value
        }
        await fetch('VMRescindProxyVote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(request)
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't set rescind");
            }
            return response.json();
        }).then(data => {
            this.setState({
                rescindSubmitted: true
            });
            this.populateMeetingAndUser();
        });

    }



    getMeetingDetails = async (vmID) => {
        const token = authService.getToken();
        await fetch('VMGetTitleWelcomeText', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't get meeting details");
            }
            return response.json();
        }).then(data => {
            this.setState({
                meeting: data
            });
        });
        this.changeTheme();
        this.getSwitches();

    }

    getSwitches = async () => {
        const token = authService.getToken();
        await fetch('VMSwitches', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't get meeting switches");
            }
            return response.json();
        }).then(async data => {
            if (data.hideAttendeeType === true) {
                this.submitMeetingAttendance(parseInt(data.voteMethodId));
            }

            this.setState({
                switches: data
            });
            if (!this.state.rescindSubmitted) {
                switch (data.proxySettingId) {
                    case 1: //user choose
                        break;
                    case 2: //must rescind
                        await this.submitPreVoteRescind(true);
                        break;
                    case 3: //must keep
                        //await this.submitPreVoteRescind(false); //actually do nothing
                        break;
                    default:
                        break;
                }
            }


            this.setState({
                contextLoading: false
            });
        });

        this.changeTheme();
    }

    submitMeetingAttendance = async (UserAttending) => {
        //not currently in use, registration decides this
        const token = authService.getToken();

        await fetch('VMAttendeeAttendancetype', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(UserAttending)
        }).then(response => {
            return response.json();
        }).then(data => {
            this.setState({ attendingSubmitted: true });

        });
    }

    populateMeetingAndUser = async () => {
        const isLoggedIn = authService.loggedIn();
        if (isLoggedIn) {
            const token = authService.getToken();
            const decoded = jwt(token);
            const user = authService.userProfile();
            var pUser = JSON.parse(user);

            this.getMeetingDetails(parseInt(decoded.VmId));
            await this.checkProxyStatus(parseInt(decoded.VmId), decoded.ScId);
            this.setUserData(parseInt(decoded.VmId), pUser, decoded.role, decoded.ScId, decoded.Test);
            await this.checkAttendanceStatus();
            await this.getSwitches();
            await this.getVoteData('ContextPopulate');
            this.setState({
                keyIncrement: this.state.keyIncrement + 1
            })
        }
    }

    getCandidatePhoto = async (contestId, candidateId) => {
        const isLoggedIn = authService.loggedIn();

        var objName = 'photo' + candidateId;

        if (!this.state[objName]) {
            if (isLoggedIn) {
                var request = {
                    ContestId: contestId,
                    CandidateId: candidateId
                }

                const token = authService.getToken();
                await fetch('VMGetCandidatePhoto', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(request)
                }).then(response => {
                    return response.json();
                }).then(data => {
                    this.setState({
                        [objName]: data.candidatePhoto
                    })
                });
            }
        }

    }

    setHubConnection = (hubConnection) => {
        this.setState({ hubConnection: hubConnection });
        return;
    }

    setContestVotedState = (contestID, voteState) => {
        var data = this.state.voteData;
        var contest = data.filter(a => a.contestID === contestID);
        contest[0].attendeesVote = voteState;
        this.setState({
            voteData: data
        })

        var activeContest = data.filter(ballot => ballot.contestStatusId === 3 || ballot.contestStatusId === 2);
        var activeCount = activeContest.length;

        var opencontest = data.filter(ballot => (ballot.contestStatusId === 3
            && (parseInt(ballot.attendeesVote) === 0 || ballot.attendeesVote === null)
            && ballot.canVote === true //is in constituency
            && this.state.user.isVoter === true
            && ballot.hasProxy === false));
        var openCount = opencontest.length;

        var pendingcontest = data.filter(ballot => ballot.contestStatusId === 5 || ballot.contestStatusId === 7);
        var pendingCount = pendingcontest.length;

        if (activeCount > 0) {
            this.setState({
                voteNotification: true,
                openVoteCount: openCount
            })
        } else if (pendingCount > 0) {
            this.setState({
                voteNotification: true,
                openVoteCount: 0
            })
        } else {
            this.setState({
                voteNotification: true,
                openVoteCount: 0
            })
        }

    }

    setQAState = (activeTab, typedMessage) => {
        this.setState({
            qAState: {
                selectedTab: activeTab,
                typedMessage: typedMessage
            }
        })
    }

    render() {
        return (
            <UserContext.Provider value={{
                fullState: this.state,
                uid: this.state.uid,
                user: this.state.user,
                meeting: this.state.meeting,
                switches: this.state.switches,
                voteNotification: this.state.voteNotification,
                messageAlert: this.state.messageAlert,
                messageCount: this.state.messageCount,
                resultsPaneOpen: this.state.resultsPaneOpen,  // Trigger for open and closing the results tab               
                keyIncrement: this.state.keyIncrement,
                
                //also contains functions
                setVoteNotification: this.setVoteNotification,
                setResultNotification: this.setResultNotification,
                setVoteStatus: this.setVoteStatus,
                getVoteData: this.getVoteData,
                voteData: this.state.voteData,
                resultData: this.state.resultData,
                getMeetingDetails: this.getMeetingDetails,
                setUserData: this.setUserData,
                populateMeetingAndUser: this.populateMeetingAndUser,
                setHubConnection: this.setHubConnection,
                addMessage: this.addMessage,
                resultsNavBarClicked: this.resultsNavBarClicked,
                setMessageValues: this.setMessageValues,
                setContestVotedState: this.setContestVotedState,
                getCandidatePhoto: this.getCandidatePhoto,
                getSwitches: this.getSwitches,

                uidGetSet: this.uidGetSet,

                getProxyStatus: this.getProxyStatus,
                checkProxyStatus: this.checkProxyStatus,
                submitPreVoteRescind: this.submitPreVoteRescind,
                setArbStatus: this.setArbStatus,
                clearContext: this.clearContext,
                setQAState: this.setQAState,
                surveyPopup: this.surveyPopup,
                setSurveyPopup: this.setSurveyPopup
            }}
            >
                {this.props.children}
            </UserContext.Provider>

        )
    }
}

