import React, { Component, Fragment } from 'react';
import UserContext from './context/UserContext';
import CloseButton from './../components/CloseButton';
import SlateEditor from './Slate/SlateEditor/Editor';

export default class Home extends Component {
    static displayName = Home.name;


    componentDidMount() {
        document.title = "CESJoinIN - Home";
    }


    renderWelcomeMessage = () => {
        let messageSource;
        if (this.context.fullState.attendanceStatus && this.context.switches.inRoomDifferentWelcome) {
            messageSource = this.context.meeting.welcomeMessageInRoom;
        } else {
            messageSource = this.context.meeting.welcomeMessage
        }

        return (
            <div className="cloudWelcome scrollContainer" key={this.context.keyIncrement}>
                <div className="d-flex justify-content-end">
                    <CloseButton />
                </div>
                <SlateEditor value={messageSource} />
            </div>
        );

    }

    render() {
        let contents = this.context.fullState.contextLoading
            ? <Fragment>
                <div className="loader"></div> </Fragment>
            : this.renderWelcomeMessage();

        return (
            <div className="scrollFix">
                {contents}
            </div>
        );
    }
}

Home.contextType = UserContext;
